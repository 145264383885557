<template>
  <div></div>
</template>

<script>
import {mapState} from 'vuex'
import orderApi from '../../api/order'
import reviewApi from '../../api/review'

export default {
    name: 'review',
    data(){
        return {
            orderId: '',
            order: null,
            loading: false,
            reviews: []
        }
    },
    computed: {
        ...mapState('client', ['token']),
        backToOrderLink(){
            if(this.orderId == '') return '#'
            return '/order/' + this.orderId
        },
        orderItems(){
            if(this.order == null) return []
            if(this.reviews.length == 0) return this.order.items
            var item = []
            for(var i=0; i < this.order.items.length; i++){
                var hasReview = false
                this.reviews.forEach(review=>{
                    if(review.order_item_id === this.order.items[i].ID) {
                        hasReview = true
                    }
                })
                if(!hasReview){
                    item.push(this.order.items[i])
                }
            }
            return item
        }
    },
    mounted(){
        this.orderId = this.$route.params.orderId
        this.initOrder()
    },
    methods: {
        initOrder(){
            this.loading = true
            orderApi.getOrderById(this.token, this.orderId).then(resp=>{
                this.order = resp
                this.getReviews()
                this.loading = false
            }).catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        getReviews(){
            reviewApi.getByOrderId(this.token, this.order.ID).then(resp=>{
                console.log(resp)
                this.reviews = resp
            })
        }
    }
}
</script>

<style>

</style>