<template>
    <div id="netImage" style="width:128px;margin-bottom:10px;margin-right:20px;">
        <div style="text-align:right;position:relative;top:15px;left:10px;z-index:99;">
            <el-button style="width:25px;height:25px;padding:5px;" type="danger" icon="el-icon-close" circle @click="deleteImageClick"></el-button>
        </div>
        <el-image style="width:128px;height:128px;" :src="item.image"></el-image>
    </div>
</template>

<script>
export default {
    name: 'netImage',
    props: ['item'],
    methods: {
        deleteImageClick(){
            console.log('deleteImageClick')
            this.$emit('deleteImage', this.item)
        }
    }
}
</script>

<style>

</style>