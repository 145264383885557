<template>
  <div v-if="!hidden" id="writeReviewItem">
      <div style="display:-webkit-box;">
        <el-image :src="item.product_image" fit="fit" style="width:64px;height:64px;border-radius:5px;border:1px solid #E4E7ED;"></el-image>
        <div style="margin-left:20px;margin-top:5px;text-align:left;">
            <div style="font-size:16px;">{{item.product_name}}</div>
            <div style="margin-top:10px;color:grey;font-size:14px;">{{item.sku_name}}</div>
        </div>
      </div>
      <div>
        <div style="text-align:left;margin-right:20px;">
            <h5>Rating</h5>
            <el-rate v-model="rate"></el-rate>
        </div>
        <div style="text-align:left;">
            <h5>Review</h5>
             <el-input type="textarea" rows="4" v-model="comment" maxlength="500" placeholder="add your review here"></el-input>
        </div>
        <div style="text-align:left;">
            <h5>Pcitures (optional)</h5>
            <div style="display:-webkit-box;">
                <net-image v-for="(image, index) in images" :key="index" :item="image" @deleteImage="deleteImage"></net-image>
            </div>
            <el-upload action="" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="fileUpload">
                <el-button size="small" type="primary" :loading="fileLoading">Upload Image</el-button>
                <div slot="tip" class="el-upload__tip">jpg/png file only，file size below 2MB</div>
            </el-upload>
        </div>
        <div style="text-align:left;">
            <el-button style="margin-top:20px;" type="danger" size="small" @click="commitReview">Confirm</el-button>
        </div>
      </div>
      <el-divider></el-divider>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import reviewApi from '../../api/review'
import aliossApi from '../../api/alioss'
import NetImage from './NetImage.vue'

export default {
    name: 'writeReviewItem',
    props: ['item'],
    components: {
        NetImage
    },
    data(){
        return{
            rate: 5,
            comment: '',
            fileLoading: false,
            images: [],
            hidden: false
        }
    },
    computed: {
        ...mapState('client', ['token'])
    },
    mounted(){
        console.log(this.item)
    },
    methods: {
        commitReview(){
            var review = {
                text: this.comment,
                score: this.rate,
                product_id: this.item.product_id,
                order_id: this.item.order_id,
                sku_id: this.item.sku_id,
                order_item_id: this.item.ID,
                images: this.images
            }
            reviewApi.create(this.token, review).then(resp=>{
                console.log(resp)
                if(resp.ID > 0) {
                    this.$message({
                        type: 'success',
                        message: 'thanks for your review.'
                    })
                    this.hidden = true
                }
            }).catch(()=>{
                this.$message.error('commit error, please retry agian')
            })
        },
        beforeAvatarUpload(file) {
            console.log(file)
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
              this.$message.error('JPG/PNG only!');
            }
            if (!isLt2M) {
              this.$message.error('file size must less than 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        fileUpload(options){
            this.fileLoading = true 
            console.log(options)
            aliossApi.uploadFile(options.file, this.token).then(imageUrl=>{
                this.fileLoading = false
                this.images.push({
                    ID:0,
                    ts: new Date().getTime(),
                    image: imageUrl
                })
                console.log(this.images)
                self.$forceUpdate()
            }).catch(()=>{
                this.fileLoading = false
            })
        },
        deleteImage(item){
            var index = -1
            for(var i=0; i<this.images.length;i++){
                if(this.images[i].ts === item.ts){
                    index = i
                    break
                }
            }
            if(index > -1){
                this.images.splice(index, 1)
            }
            console.log(this.images)
            this.$forceUpdate()
        },
    }
}
</script>

<style>
#writeReviewItem{
    padding: 0px;
}
</style>